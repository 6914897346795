import React from "react"

import Layout from "../components/layout/Layout"
import Seo from "../components/SEO"
import Title from "../components/Title"

const Privacy = () => {
  return (
    <Layout>
      <Seo
        title="Privacy Policy"
        desc="How we collect, use, and share information and personal data we obtain through your use of the Internet sites."
      />
      <div className="privacy-page">
        <Title title="Privacy Policy" />
        <div className="privacy-content">
          <div>
            <p style={{ fontStyle: "italic" }}>
              Updated and Effective as of September 1, 2021.
            </p>
            <p>
              We have prepared this privacy policy (“Privacy Policy”) to explain
              to you how we collect, use, and share information and Personal
              Data (as defined under applicable law) we obtain through your use
              of the Internet sites, applications and online services
              (“Services”) that are operated by, controlled by or affiliated
              with Vietnamese Guide. This Privacy Policy only covers information
              collected through the Services and via direct communications
              between you and Vietnamese Guide, and does not cover any
              information collected at any other website, application or
              otherwise by us (unless specifically stated), including when you
              call us, write to us, or communicate with us in any manner other
              than through the Services. By using the Services, you consent to
              such use of your information and Personal Data and agree to the
              terms of this Privacy Policy.
            </p>
          </div>
          <div>
            <h3>Information We Automatically Collect</h3>
            <p>
              We and our third-party service providers (including any
              third-party content, advertising, and analytics providers) may
              automatically collect certain information from your device or web
              browser when you interact with the Services to help us understand
              how our users use the Services and to target advertising to you
              (which we will refer to in this Privacy Policy collectively as
              “Usage Data”). For example, each time you visit the Services we
              and our third-party service providers may automatically collect
              your IP address, mobile device identifier or other unique
              identifier, browser and computer type, access time, the Web page
              you came from, the URL you go to next, the Web page(s) that you
              access during your visit and your interaction with content or
              advertising on the Services.
            </p>
            <p>
              We and our third-party service providers may use such Usage Data
              for a variety of purposes including to diagnose problems with our
              servers and software, to administer the Services, to gather
              demographic information and to target advertising to you on the
              Services and elsewhere online. Accordingly, our third-party
              advertising networks and ad servers will also provide us with
              information, including reports that will tell us how many ads were
              presented and clicked on the Services in a manner that does not
              identify personally any specific individual. The Usage Data we
              collect is generally non-identifying, but if we associate it with
              you as a specific and identifiable person, we will treat it as
              Personal Data.
            </p>
          </div>
          <div>
            <h3>Cookies/Tracking Technologies</h3>
            <p>
              We may use tracking technologies, such as cookies and local
              storage.
            </p>
          </div>
          <div>
            <h3>Choice/Opt-Out From Communications</h3>
            <p>
              We offer you the opportunity to manage your communications from
              us. Even after subscribing to one or more newsletters and/or
              opting in to one or more offers to receive marketing and/or
              promotional communications from us or our third-party partners,
              users may elect to modify their preferences by following the
              “Unsubscribe” link provided in an email or communication received.
              You may also be able to change your preferences by updating your
              profile or account, depending on which of our Services you are
              using. Please be aware that if you wish to remove yourself from a
              newsletter and/or other marketing emails from third parties that
              you consented to through the Services, you must do so by
              contacting the relevant third-party. Even if you do opt-out of
              marketing emails, we reserve the right to send you transactional
              and administrative emails including those related to the Services,
              service announcements, notices of changes to this Privacy Policy
              or other Services policies, and to contact you regarding any goods
              or services you have ordered.
            </p>
          </div>
          <div>
            <h3>Modifying and Deleting Your Personal Data</h3>
            <p>
              You may access the information we hold about you. If you wish to
              exercise this right, please contact us using the details in the
              Contact Us section below. If you would like to update, correct,
              modify or delete from our database any Personal Data you
              previously submitted to us, please let us know by accessing and
              updating your profile. If you delete certain information you may
              not be able to order services in the future without re-submitting
              such information. We will comply with your request as soon as
              reasonably practicable. Also, please note that we will maintain
              Personal Data in our database whenever we are required to do so by
              law.
            </p>
            <p>
              Please note that we may need to retain certain information for
              recordkeeping purposes and/or to complete any transactions that
              you began prior to requesting such change or deletion (for
              example, when you enter a promotion, you may not be able to change
              or delete the Personal Data provided until after the completion of
              such promotion). We will retain your Personal Data for the period
              necessary to fulfill the purposes outlined in this Policy unless a
              longer retention period is required or permitted by law.
            </p>
          </div>
          <div>
            <h3>Links</h3>
            <p>
              The Services may contain links to other websites that we do not
              control, and the Services may contain videos, advertising and
              other content hosted and served by third parties. We are not
              responsible for the privacy practices of any third-party.
            </p>
          </div>
          <div>
            <h3>Children’s Privacy</h3>
            <p>
              The Services are intended for a general audience and are not
              intended for and may not be used by children under the age of 13.
              We do not knowingly collect information from children under the
              age of 13 and we do not target the Services to children under the
              age of 13. If a parent or guardian becomes aware that his or her
              child has provided us with information without their consent, he
              or she should contact us using the details in the Contact Us
              section below. We will delete such information from our files as
              soon as reasonably practicable.
            </p>
          </div>
          <div>
            <h3>Sensitive Personal Data</h3>
            <p>
              Subject to the following paragraph, we ask that you not send us,
              and you not disclose, any sensitive personal data (for example,
              social security numbers, information related to racial or ethnic
              origin, political opinions, religion or other beliefs, health,
              biometrics or genetic characteristics, criminal background or
              trade union membership) on or through the Services or otherwise to
              us.
            </p>
            <p>
              If you send or disclose any sensitive personal data to us, you
              consent to our processing and use of such sensitive personal data
              in accordance with this Privacy Policy. If you do not consent to
              our processing and use of such sensitive personal data, you must
              not submit such content to our Services.
            </p>
          </div>
          <div>
            <h3>Changes</h3>
            <p>
              We may update this Privacy Policy from time to time in our
              discretion and will notify you of any material changes to the way
              in which we treat Personal Data, by posting a notice on relevant
              areas of the Services. We may also provide notice to you in other
              ways in our discretion, such as through contact information you
              have provided. Any updated version of this Privacy Policy will be
              effective immediately upon the posting of the revised Privacy
              Policy unless otherwise specified. Your continued use of the
              Services after the effective date of the revised Privacy Policy
              (or such other act specified at that time) will constitute your
              consent to those changes. However, we will not, without your
              consent, use your Personal Data in a manner materially different
              than what was stated at the time your Personal Data was collected.
            </p>
          </div>
          <div>
            <h3>Contact us</h3>
            <p>
              If you have any questions about this Privacy Policy, please feel
              free to contact us by email at hello.vietnameseguide@gmail.com.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Privacy
